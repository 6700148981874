.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes slowBounce {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.animate-slow-left-right {
  animation: slowBounce 3s infinite ease-in-out; /* Slower animation */
}

@keyframes slowTopBottom {
  0%, 100% {
    transform: translateY(0); /* Original position */
  }
  50% {
    transform: translateY(20px); /* Move down 20px */
  }
}

.animate-slow-top-bottom {
  animation: slowTopBottom 3s infinite ease-in-out; /* Slow animation */
}
.cursor-red {
  @apply caret-red-500; /* Tailwind's `caret` utility for caret color */
}